import { useEffect, useState } from "react";
import { db, firebase } from "../modules";

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  // Firebase Auth...
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        setLoadingUser(false);
        const docRef = db.collection("tokens").doc(user.uid);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              setToken(doc.data().token);
            } else {
              setToken(null);
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        setCurrentUser(null);
        setLoadingUser(false);
        console.log("No user logged in...");
      }
    });
    return () => {
      firebase.auth().signOut();
    };
  }, []);
  return { currentUser, token, loadingUser };
};

export default useCurrentUser;
