// Firebase App (the core Firebase SDK) is always required and must be listed first...
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics...
import "firebase/analytics";
// Add the Firebase products that you want to use...
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  // apiKey: "AIzaSyAFcshjUjCVPswyrcE2zzFcbklrghbMsCw",
  // authDomain: "taskly-b853f.firebaseapp.com",
  // projectId: "taskly-b853f",
  // storageBucket: "taskly-b853f.appspot.com",
  // messagingSenderId: "192376550733",
  // appId: "1:192376550733:web:21bcb8a9a55941810d3304",
  apiKey: "AIzaSyBvNo4KDNKppiG3DggK_5Soax7Nb2-Dn1o",
  authDomain: "todocode.web.app",
  databaseURL: "https://devmayeapps.firebaseio.com",
  projectId: "devmayeapps",
  storageBucket: "devmayeapps.appspot.com",
  messagingSenderId: "1007210373099",
  appId: "1:1007210373099:web:6b34c892ffa9d29ca883ae",
  measurementId: "G-146FH1Q4HJ"
};

// Initialize Firebase...
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const firestore = firebase.firestore;
const analytics = firebase.analytics();

// Log analytics events...
analytics.logEvent("notification_received");

// Ebable offline...
db.enablePersistence().catch((err) => {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled in one tab at a a time
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
  }
});
// Subsequent queries will use persistence, if it was enabled successfully
export { firebase, firestore, db, analytics };
