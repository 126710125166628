import moment from "moment";

// Calculate time since...
const calculateTimeSince = (date) => {
  let then = moment(date);
  let now = moment();
  if (now.diff(then, "minutes") < 60)
    return now.diff(then, "minutes") + "m ago";
  if (now.diff(then, "hours") < 24) return now.diff(then, "hours") + "h ago";
  return now.diff(then, "days") + "d ago";
};

const dateFormatter = (timestamp) => {
  const date = timestamp.seconds ? timestamp.toDate() : timestamp;
  const validDate = new Date(date);
  // Set UTC + 3 for firestore timestamp...
  if (timestamp.seconds) validDate.setHours(validDate.getHours());
  // Set date...
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const created_on = validDate.toLocaleDateString(undefined, options);
  const created_at = validDate.toLocaleTimeString();
  const current_date = new Date();
  const today = current_date.toLocaleDateString(undefined, options);
  const dateText =
    created_on === today
      ? `Today at ${created_at}`
      : date.toLocaleDateString(undefined, options);
  return {
    created_at,
    created_on: dateText,
    time_since: calculateTimeSince(date),
  };
};

export default dateFormatter;
