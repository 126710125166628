import { ScreenFullIcon, ScreenNormalIcon } from "@primer/octicons-react";
import React, { useState } from "react";
import { Sidebar, Aside, NewsAlert } from "../components";

const Archive = () => {
  const [expand, setExpand] = useState(true);
  // Handle expand...
  const handleExpand = (event) => {
    event.preventDefault();
    setExpand((expand) => !expand);
  };
  return (
    <main>
      <section className="home-page">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <h3>Archive</h3>
              <span
                className="top-header-inbox set-expand"
                onClick={handleExpand}
                title="Fullscreen"
              >
                {expand ? <ScreenNormalIcon /> : <ScreenFullIcon />}
              </span>
            </div>
            <NewsAlert message="This is a premium feature and still in development. Stay tuned for the release!" />
          </section>
        </section>
        <Aside expand={expand} />
      </section>
    </main>
  );
};
export default Archive;
