import React from "react";
import Logs from "../assets/images/Logs.svg";

const NoTasks = ({ result = true, message = "No tasks to show" }) => {
  return (
    <section className="no-tasks">
      <img
        style={result ? {} : { display: "none" }}
        src={Logs}
        alt="Not found"
      />
      <div className="no-tasks-title">{message}</div>
    </section>
  );
};

export default NoTasks;
