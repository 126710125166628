import {
  MarkGithubIcon,
  StarIcon,
  RocketIcon,
  SignOutIcon,
  HomeIcon,
  CheckCircleIcon,
  GearIcon,
  ArchiveIcon,
  SearchIcon,
} from "@primer/octicons-react";
import React from "react";
import { NavLink } from "react-router-dom";
import SidebarProfile from "./SidebarProfile";
import { analytics, firebase } from "../modules";
import pwafire from "pwafire";
const { pwa } = pwafire;

// Sidebar...
const Sidebar = () => {
  return (
    <section className="main-sidebar">
      <section className="sidebar-inner">
        <SidebarProfile />
        <div className="sidebar-inner-top">
          <NavLink exact to="/" className="navigate">
            <span className="navigate-inner">
              {" "}
              <HomeIcon size={24} /> <span className="label-text">Home</span>
            </span>
          </NavLink>
          <NavLink to="/search" className="navigate">
            <span className="navigate-inner">
              {" "}
              <SearchIcon size={24} />{" "}
              <span className="label-text">Search</span>
            </span>
          </NavLink>
          <NavLink to="/github" className="navigate">
            <span className="navigate-inner">
              {" "}
              <MarkGithubIcon size={24} />{" "}
              <span className="label-text">GitHub</span>
            </span>
          </NavLink>
          <NavLink to="/general" className="navigate">
            <span className="navigate-inner">
              {" "}
              <StarIcon size={24} /> <span className="label-text">General</span>
            </span>
          </NavLink>
          <NavLink to="/completed" className="navigate">
            <span className="navigate-inner">
              {" "}
              <CheckCircleIcon size={24} />{" "}
              <span className="label-text">Completed</span>
            </span>
          </NavLink>
          {/* <NavLink exact to="/" className="navigate">
            <span className="navigate-inner">
              {" "}
              <SearchIcon size={24} /> <span className="label-text">Search</span>
            </span>
          </NavLink> */}
          <NavLink to="/archive" className="navigate">
            <span className="navigate-inner">
              {" "}
              <ArchiveIcon size={24} />{" "}
              <span className="label-text">Archive</span>
            </span>
          </NavLink>
        </div>

        <div className="view sidebar-inner-bottom">
          <NavLink
            to="/productivity"
            className="navigate"
            onClick={() => {
              // event.preventDefault();
              analytics.logEvent("use_feature", {
                content_type: "page",
                path: "/productivity",
                feature: "productivity",
              });
            }}
          >
            <span className="navigate-inner">
              {" "}
              <RocketIcon size={24} />{" "}
              <span className="label-text">Productivity</span>
            </span>
          </NavLink>
          <NavLink to="/settings" className="navigate">
            <span className="navigate-inner">
              {" "}
              <GearIcon size={24} />{" "}
              <span className="label-text">Settings</span>
            </span>
          </NavLink>

          <div className="navigate">
            <span
              className="navigate-inner"
              onClick={(event) => {
                event.preventDefault();
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    // Sign-out successful.
                    pwa.clearBadge();
                  })
                  .catch((error) => {
                    // An error happened.
                  });
              }}
            >
              {" "}
              <SignOutIcon size={24} />{" "}
              <span className="label-text">Sign out</span>
            </span>
          </div>
        </div>
      </section>
    </section>
  );
};


export default Sidebar;
