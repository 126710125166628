import { createContext, useEffect, useState } from "react";
import { Octokit } from "@octokit/rest";
import Loader from "react-loader-spinner";
import { useCurrentUser } from "../hooks";

// Context...
const AuthContext = createContext({
  currentUser: null,
  octokit: null,
  gitUser: null,
});

// Auth Context...
const AuthProvider = ({ children }) => {
  const { currentUser, token, loadingUser } = useCurrentUser();
  const [octokit, setOctokit] = useState(null);
  const [gitUser, setGitUser] = useState(null);

  // GitHub API
  useEffect(() => {
    // Octokit init...
    if (token) {
      const octokit = new Octokit({
        auth: token,
      });
      setOctokit(octokit);
      // Set GitHub user....
      const getUser = async () => {
        const user = await octokit.users.getAuthenticated();
        setGitUser(user.data);
      };
      getUser();
    } else {
      // Do nothing...
    }
    return () => {
      // Do nothing...
    };
  }, [token]);

  if (loadingUser) {
    return (
      <section className="load-page">
        <div className="anim-pulse">
          <Loader type="ThreeDots" color="#000" height={40} width={40} />
          <small style={{fontSize : 15}} >Loading</small>
        </div>
      </section>
    );
  } else {
    return (
      <AuthContext.Provider value={{ currentUser, octokit, gitUser }}>
        {children}
      </AuthContext.Provider>
    );
  }
};

export { AuthContext, AuthProvider };
