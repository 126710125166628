import React, { useContext, useState } from "react";
import { Avatar, AvatarStack } from "@primer/components";
import {
  ArrowLeftIcon,
  CheckIcon,
  CopyIcon,
  XIcon,
} from "@primer/octicons-react";
import { pwa } from "pwafire";
import { AuthContext } from "../contexts";
import Maye from "../assets/images/Maye.jpeg";
import Kiara from "../assets/images/Kiara.png";
import "../assets/styles/modal.css";

const Modal = ({ close, setClose }) => {
  const { currentUser } = useContext(AuthContext);
  const [isMaye, setIsMaye] = useState(false);
  const [isKiara, setIsKiara] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const closeModal = (event) => {
    event.preventDefault();
    setClose(true);
  };
  return (
    <section
      style={close ? { display: "none" } : {}}
      className="modal-alert-overlay"
    >
      <section className="modal-alert">
        <div className="modal-title">
          <div className="modal-title-content">
            {isMaye || isKiara
              ? "About the team"
              : currentUser.displayName
              ? `Hey ${currentUser.displayName.split(" ")[0]},`
              : "Hey,"}
          </div>
          <span
            style={isMaye || isKiara ? { marginRight: 5 } : { display: "none" }}
            className="modal-icon"
            onClick={(event) => {
              event.preventDefault();
              setIsMaye(false);
              setIsKiara(false);
            }}
          >
            <ArrowLeftIcon size={24} />
          </span>
          <span className="modal-icon" onClick={closeModal}>
            <XIcon size={24} />
          </span>
        </div>
        <section className="modal-content">
          {/*<div contentEditable={true} className="issue-comment">
            Type here....
            </div>
         <button className="comment-btn" type="button">
           Comment
         </button>*/}
          <section
            className="fade-in"
            style={isMaye || isKiara ? { display: "none" } : {}}
          >
            <div>
              {" "}
              Thanks for creating an account and being one of our early users.
              Email us via{" "}
              <span style={{ fontWeight: 500 }}>devmaye@gmail.com</span> for any
              questions or help. Help us spread the message. We love you!
            </div>
            <div
              className="modal-share-link"
              onClick={async (event) => {
                event.preventDefault();
                try {
                  const res = await pwa.copyText("https://kazi.pwafire.org");
                  if (res) {
                    setLinkCopied(true);
                    setTimeout(() => {
                      setLinkCopied(false);
                    }, 2000);
                  } else {
                    setLinkCopied(false);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Sharable link :{" "}
              <span className="modal-share-link-icon">
                {linkCopied ? <CheckIcon /> : <CopyIcon />}
              </span>{" "}
            </div>
            <div className="footnote">Maye & Velda</div>
            <div>
              <AvatarStack>
                <Avatar
                  alt="Maye Edwin"
                  src={Maye}
                  title="Maye Edwin"
                  onClick={(event) => {
                    event.preventDefault();
                    setIsMaye(true);
                    setIsKiara(false);
                  }}
                />
                <Avatar
                  onClick={(event) => {
                    event.preventDefault();
                    setIsMaye(false);
                    setIsKiara(true);
                  }}
                  alt="Velda Kiara"
                  src={Kiara}
                  title="Velda Kiara"
                />
              </AvatarStack>
            </div>
          </section>
          <section
            style={isMaye ? {} : { display: "none" }}
            className="modal-bio fade-in"
          >
            <img src={Maye} className="modal-bio-avatar" alt="Maye Edwin" />{" "}
            <div className="modal-bio-desc">
              <div>
                {" "}
                Maye Edwin is a Software Engineer based in Nairobi, Kenya (
                <a
                  className="modal-bio-social"
                  href="https://twitter.com/mayeedwin1"
                  target="_blank"
                  rel="noreferrer"
                >
                  @mayeedwin1
                </a>
                )
              </div>
              <small className="modal-bio-label">Team Lead</small>
            </div>
          </section>
          <section
            style={isKiara ? {} : { display: "none" }}
            className="modal-bio fade-in"
          >
            <img src={Kiara} className="modal-bio-avatar" alt="Velda Kiara" />{" "}
            <div className="modal-bio-desc">
              <div>
                {" "}
                Velda Kiara is a PM and Software Engineer based in Nairobi,
                Kenya (
                <a
                  className="modal-bio-social"
                  href="https://twitter.com/veldakiara"
                  target="_blank"
                  rel="noreferrer"
                >
                  @veldakiara
                </a>
                )
              </div>
              <small className="modal-bio-label">Frontend Engineer</small>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Modal;
