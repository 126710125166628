import { useContext, useEffect, useRef, useState } from "react";
import {
  BellIcon,
  CheckIcon,
  DashIcon,
  PlusIcon,
  ScreenFullIcon,
  ScreenNormalIcon,
} from "@primer/octicons-react";
import swal from "sweetalert";
import {
  Sidebar,
  Aside,
  TaskItem,
  NewsAlert,
  Modal,
  RepoList,
} from "../components";
import { db, firebase } from "../modules";
import { AuthContext, DataContext } from "../contexts";

const HomePage = () => {
  // Contexts...
  const { currentUser, octokit, gitUser } = useContext(AuthContext);
  const { uid, displayName, photoURL, email } = currentUser;
  const { tasks } = useContext(DataContext);
  // Page title...
  document.title = `Home - ${displayName}`;
  // States...
  const [checked, setChecked] = useState(false);
  const [comment, setComment] = useState("");
  const [created, setCreated] = useState(false);
  const [saving, setSaving] = useState(false);
  const [close, setClose] = useState(true);
  const [expand, setExpand] = useState(true);
  const [repoModal, setRepoModal] = useState(true);
  const [repos, setRepos] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [selectedRepo, setSelectedRepo] = useState("");
  const [visibility, setVisibility] = useState("all");
  const [showAddComment, setShowAddComment] = useState(false);

  const commentRef = useRef(null);
  // Handle inbox...
  const handleInbox = (event) => {
    event.preventDefault();
    setClose(false);
  };

  // Handle expand...
  const handleExpand = (event) => {
    event.preventDefault();
    setExpand((expand) => !expand);
  };
  // Handle click...
  const useClick = (e) => {
    e.preventDefault();
    setChecked(!checked);
  };

  // Handle repo modal....
  useEffect(() => {
    if (checked) {
      setRepoModal((prevState) => !prevState);
    } else {
      // Do nothing...
    }
    return () => {
      // Do nothing...
      setRepoModal(true);
    };
  }, [checked]);

  const handleRepoModal = (event) => {
    event.preventDefault();
    setRepoModal(false);
  };

  // Handle comment change...
  const handleComment = (event) => {
    const text = event.currentTarget.textContent;
    setComment(text);
  };

  // Handle create...
  const handleTaskCreation = async (event) => {
    try {
      event.preventDefault();
      setSaving(true);
      const { task, repo } = event.target;
      // Task data...
      const taskData = {
        uid: uid,
        type: checked ? "github" : "general",
        title: task.value,
        body: comment === "Issue comment..." ? "" : comment,
        repo: repo.value,
        completed: false,
        archived: false,
        date_updated: new Date(),
        date_created: new Date(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          displayName,
          photoURL,
          email,
        },
      };

      // Post to GitHub...
      if (checked && gitUser) {
        const repoInfo = repo.value.split("/");
        try {
          const result = await octokit.issues.create({
            owner: repo.value.split("/")[0],
            repo: repo.value.split("/")[1],
            title: task.value,
            body: comment === "Issue comment..." ? "" : comment,
            labels: ["kazi"],
          });
          // If created on GitHub, save to db...
          if (result) {
            const { html_url, created_at, updated_at, number, labels, id } =
              result.data;
            const created = await db.collection("tasks").add({
              ...taskData,
              html_url: html_url,
              created_at: created_at,
              updated_at: updated_at,
              number: number,
              labels: labels,
              owner: repoInfo[0],
              repo: repoInfo[1],
              id: id,
            });
            // Success...
            if (created) {
              // Created....
              setSaving(false);
              setCreated(true);
              setChecked(false);
              setTimeout(() => {
                setCreated(false);
              }, 2000);
              // Reset...
              task.value = "";
              repo.value = "";
              if (commentRef.current) {
                commentRef.current.innerText = "Issue comment";
              }
            }
          }
        } catch (error) {
          setSaving(false);
          swal("Failed, try again", {
            buttons: false,
            timer: 3000,
            icon: "error",
          });
        }
      } else {
        const result = await db.collection("tasks").add(taskData);
        if (result) {
          // Created....
          setCreated(true);
          setChecked(false);
          setTimeout(() => {
            setCreated(false);
          }, 2000);
          // Reset...
          setSaving(false);
          task.value = "";
          repo.value = "";
        }
      }
    } catch (error) {
      setSaving(false);
      swal("Failed, try again", {
        buttons: false,
        timer: 3000,
        icon: "error",
      });
    }
  };

  // Get user repos...
  useEffect(() => {
    const getUserRepos = async () => {
      try {
        // Get user repos...
        setFetching(true);
        setRepos(null);
        const result = await octokit.repos.listForAuthenticatedUser({
          per_page: 100,
          visibility: visibility,
          sort: "full_name",
        });
        if (result) {
          setRepos(result.data);
          setFetching(false);
        } else {
          setFetching(false);
        }
      } catch (error) {
        setFetching(false);
        console.log(error);
      }
    };
    if (octokit) getUserRepos();

    return () => {
      // Do nothing...
      setRepos(null);
    };
  }, [octokit, visibility]);

  // Show selected...
  const handleChange = (event) => {
    event.preventDefault();
    setSelectedRepo(event.target.value);
  };

  // Handle add comment...
  const handleCommentAdd = async (event) => {
    event.preventDefault();
    setShowAddComment((prev) => !prev);
  };

  // Task component list...
  const taskItems = tasks.map((task) => <TaskItem key={task.id} doc={task} />);
  return (
    <main>
      <section className="home-page not-mobile fadein">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <h3>Home</h3>
              <span
                className="top-header-inbox"
                onClick={handleInbox}
                title="Inbox"
              >
                <BellIcon />
              </span>
              <span
                className="top-header-inbox set-expand"
                onClick={handleExpand}
                title="Fullscreen"
              >
                {expand ? <ScreenNormalIcon /> : <ScreenFullIcon />}
              </span>
            </div>
            <form className="parent-form fade-in" onSubmit={handleTaskCreation}>
              <input
                name="task"
                className="task-text"
                autoComplete="off"
                type="text"
                maxLength="85"
                placeholder="Hey 👋, what's up today?"
                required={true}
              />
              {/* <div
              className="comment-label"
              style={checked ? { display: "flex" } : { display: "none" }}
            >
              <DotIcon /> <span style={{ marginLeft: 5 }}>Repository</span>
            </div> */}
              <div
                className="select-repo"
                style={checked ? { display: "flex" } : { display: "none" }}
              >
                <input
                  onChange={handleChange}
                  name="repo"
                  type="text"
                  className="repo-text"
                  placeholder="Type repository name..."
                  spellCheck="false"
                  autoComplete="off"
                  value={selectedRepo}
                  title="Repository name"
                  required={checked ? true : false}
                />
                <span onClick={handleRepoModal} className="select-repo-btn">
                  Select
                </span>
              </div>
              <div
                onClick={handleCommentAdd}
                className="comment-label"
                style={
                  checked
                    ? { display: "flex", cursor: "pointer", userSelect: "none" }
                    : { display: "none" }
                }
              >
                <span style={{ marginLeft: 5, width: "100%" }}>
                  Add comment
                </span>
                {showAddComment ? <DashIcon /> : <PlusIcon />}{" "}
              </div>
              <div
                ref={commentRef}
                onInput={handleComment}
                contentEditable={true}
                suppressContentEditableWarning={true}
                className="comment-body"
                style={
                  checked && showAddComment
                    ? { display: "block" }
                    : { display: "none" }
                }
                required={checked ? true : false}
              >
                Issue comment....
              </div>
              <section className="create-footer">
                <div className="date-time">
                  <div className="checkbox">
                    <div
                      onClick={useClick}
                      className={
                        checked ? "checkmark checkbox-check" : "checkmark"
                      }
                    >
                      {checked ? <CheckIcon /> : ""}
                    </div>
                    <div className="checkbox-label" title="Mark as GitHub Todo">
                      &nbsp;GitHub Task
                    </div>
                  </div>
                </div>
                <button
                  disabled={saving ? true : false}
                  style={saving ? { opacity: 5 } : {}}
                  className={
                    created ? "create-btn created-success" : "create-btn"
                  }
                >
                  {created ? (
                    <span className="created-btn-inner fade-in">
                      <CheckIcon />
                      <span style={{ marginLeft: 6 }}>Created</span>
                    </span>
                  ) : saving ? (
                    "Saving"
                  ) : (
                    "Create"
                  )}
                </button>
              </section>
            </form>
            <section className="tasks-container">{taskItems}</section>
          </section>
        </section>
        <Aside expand={expand} />
      </section>
      <Modal close={close} setClose={setClose} />
      <RepoList
        close={repoModal}
        fetching={fetching}
        repos={repos}
        setClose={setRepoModal}
        setSelectedRepo={setSelectedRepo}
        setVisibility={setVisibility}
      />
      <section className="is-desktop">
        <NewsAlert message="Ooops! This App is ONLY available for desktop devices currently!" />
      </section>
    </main>
  );
};

export default HomePage;
