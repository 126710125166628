import React, { useContext, useEffect, useState } from "react";
import { Sidebar, TaskItem, Aside, NoTasks } from "../components";
import { AuthContext } from "../contexts";
import { db } from "../modules";
import "../assets/kazi/task-item.css";

const AllPage = () => {
  const { currentUser } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    const getTasks = () => {
      db.collection("tasks")
        .orderBy("timestamp", "desc")
        .where("uid", "==", currentUser.uid)
        .onSnapshot((querySnapshot) => {
          const getResult = [];
          querySnapshot.forEach((doc) => {
            if (!doc.data().completed) {
              getResult.push(doc);
            }
          });
          setTasks(getResult);
        });
    };
    getTasks();
  }, [currentUser.uid]);

  // Task component list...
  const taskItems = tasks.map((task) => <TaskItem key={task.id} doc={task} />);

  return (
    <main>
      <section className="home-page">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <h3>All Tasks</h3>
            </div>
            <section className="tasks-container">
              {taskItems.length > 0 ? taskItems : <NoTasks />}
            </section>
          </section>
        </section>
        <Aside />
      </section>
    </main>
  );
};

export default AllPage;
