import React from "react";
import Routes from "./routes/Routes";
import "./index.css";
import "./assets/kazi/dropdown.css";
import "./assets/kazi/date.css";
import "./assets/kazi/markdown.css";
import "./assets/kazi/checkbox.css";
import "./assets/kazi/todos.css";
import "./assets/kazi/fadein.css";
import "./assets/kazi/productivity.css";
import "./assets/kazi/docs.css";
import "./assets/kazi/timeline.css";
import "./assets/kazi/animate.css";
import "./assets/kazi/legal.css";
import "./assets/styles/fadein.css";
import "./assets/kazi/alert.css";

const App = () => {
  return <Routes />;
};

export default App;
