import React, { useContext, useEffect, useState } from "react";
import { filtered } from "filtered";
import {
  Sidebar,
  Aside,
  TaskItem,
  NewsAlert,
  Modal,
  SearchInput,
  NoTasks,
} from "../components";
import { db } from "../modules";
import { ScreenFullIcon, ScreenNormalIcon } from "@primer/octicons-react";
import { AuthContext } from "../contexts";

const SearchBar = () => {
  const { currentUser } = useContext(AuthContext);
  const { displayName } = currentUser;
  document.title = `Home - ${displayName}`;
  const [expand, setExpand] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [foundTasks, setFoundTasks] = useState([]);
  const [close, setClose] = useState(true);

  // Handle expand...
  const handleExpand = (event) => {
    event.preventDefault();
    setExpand((expand) => !expand);
  };

  // Filter tasks, algo...
  const doFilter = (event) => {
    event.preventDefault();
    // Query tasks...
    const result = filtered({
      data: tasks,
      firestore: true,
      filter: "title",
      query: event.target.value,
    });
    setFoundTasks(result);
  };

  // Effect...
  useEffect(() => {
    let unmounted = false;
    const getTasks = () => {
      db.collection("tasks")
        .orderBy("timestamp", "desc")
        .where("uid", "==", currentUser.uid)
        .onSnapshot((querySnapshot) => {
          const completed = [];
          const pending = [];
          const getResult = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().completed) {
              completed.push(doc.id);
            } else {
              pending.push(doc.id);
              getResult.push(doc);
            }
          });
          // Set...
          if (!unmounted) {
            setTasks(getResult);
            setFoundTasks(getResult);
          }
        });
    };
    getTasks();
    return () => {
      unmounted = true;
    };
  }, [currentUser.uid]);

  // Task component list...
  const taskItems = foundTasks.map((task) => (
    <TaskItem key={task.id} doc={task} />
  ));

  return (
    <main>
      <section className="home-page not-mobile fadein">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <SearchInput
                className="search-component-search"
                placeHolder="Search tasks..."
                onChange={doFilter}
              />

              <span
                className="top-header-inbox set-expand"
                onClick={handleExpand}
                title="Fullscreen"
              >
                {expand ? <ScreenNormalIcon /> : <ScreenFullIcon />}
              </span>
            </div>
            <section className="tasks-container">
              {taskItems.length > 0 ? (
                <section className="tasks-container">{taskItems}</section>
              ) : (
                <NoTasks result={false} message="No result found" />
              )}
            </section>
          </section>
        </section>
        <Aside expand={expand} />
      </section>
      <Modal close={close} setClose={setClose} />
      <section className="is-desktop">
        <NewsAlert message="Ooops! This App is ONLY available for desktop devices currently!" />
      </section>
    </main>
  );
};

export default SearchBar;
