import React, { useContext } from "react";
import { AuthContext } from "../contexts";

const NewsAlert = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <section className="news-alert">
      <img
        style={{ margin: 0 }}
        className="side-avatar"
        src="https://avatars.githubusercontent.com/u/17247358?v=4"
        alt="M"
        title="Maye Edwin"
      />
      <div className="news-alert-title">
        {currentUser
          ? `Hey ${currentUser.displayName.split(" ")[0]}!`
          : "Hey there!"}
      </div>
      <div className="news-alert-desc">{message}</div>
      <small className="news-alert-footer">
        Send feedback via devmaye@gmail.com
      </small>
    </section>
  );
};

export default NewsAlert;
