import React, { useContext } from "react";
import { ChevronRightIcon } from "@primer/octicons-react";
import { Link } from "react-router-dom";
import { Aside, Sidebar } from "../components";
import { AuthContext } from "../contexts";
import "../assets/styles/settings.css";

const Settings = () => {
  const { currentUser } = useContext(AuthContext);
  const { displayName, email } = currentUser;
  return (
    <main>
      <section className="home-page">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <h3>Your Account</h3>
            </div>
            <section>
              <Link className="account-item" to="/settings/name">
                <div className="account-item-left">
                  <div className="account-label">Name</div>
                  <div className="account-name">
                    {displayName ? displayName : "No name"}
                  </div>
                </div>
                <div className="account-item-right">
                  <ChevronRightIcon />
                </div>
              </Link>
              <Link className="account-item" to="/settings/email">
                <div className="account-item-left">
                  <div className="account-label">Email</div>
                  <div className="account-name">
                    {email ? email : "No email"}
                  </div>
                </div>
                <div className="account-item-right">
                  <ChevronRightIcon />
                </div>
              </Link>
            </section>
          </section>
        </section>
        <Aside />
      </section>
    </main>
  );
};
export default Settings;
