import { useContext, useState } from "react";
import {
  ChevronRightIcon,
  DownloadIcon,
  IssueClosedIcon,
  IssueOpenedIcon,
} from "@primer/octicons-react";
import { Link } from "react-router-dom";
import { db, firebase } from "../modules";
import swal from "sweetalert";
import { AuthContext, DataContext } from "../contexts";
import "../assets/styles/aside.css";

const Aside = ({ expand }) => {
  const { currentUser, octokit } = useContext(AuthContext);
  const { uid, displayName, photoURL, email } = currentUser;
  const { tasks, counts } = useContext(DataContext);
  const [importing, setImporting] = useState(false);

  // Get github issues...
  const getGitHubIssues = async (event) => {
    event.preventDefault();
    try {
      setImporting(true);
      const result = await octokit.issues.list({
        filter: "assigned",
        state: "open",
      });
      // console.log(result.data);
      // Task data...
      const taskData = {
        uid: uid,
        type: "github",
        completed: false,
        archived: false,
        date_updated: new Date(),
        date_created: new Date(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          displayName,
          photoURL,
          email,
        },
      };
      // Add tasks...
      const taskList = [];
      // Get tasklist, as doc.data()...
      if (tasks) {
        tasks.forEach((task) => taskList.push(task.data()));
      } else {
        // Do nothing...
      }
      // Check if result...
      if (result.data.length > 0) {
        setImporting(false);
        let count = 0;
        result.data.forEach((issue) => {
          const found = taskList.find((item) => item.id === issue.id);
          const {
            html_url,
            created_at,
            updated_at,
            number,
            labels,
            id,
            repository,
            body,
            title,
          } = issue;
          if (!found) {
            // Add task...
            db.collection("tasks").add({
              ...taskData,
              id,
              body,
              title,
              html_url,
              created_at,
              updated_at,
              number,
              labels,
              owner: repository.owner.login,
              repo: repository.name,
            });
          } else {
            // Do nothing...
            count++;
          }
          if (count > 0) {
            swal(`You have ${count} imported issues`, {
              buttons: false,
              timer: 2000,
              icon: "info",
            });
          }
        });
      } else {
        setImporting(false);
        swal("You have 0 assigned issues", {
          buttons: false,
          timer: 3000,
          icon: "warning",
        });
      }
    } catch (error) {
      setImporting(false);
      swal("Sorry, an error occured!", {
        buttons: false,
        timer: 3000,
        icon: "error",
      });
    }
  };

  return (
    <aside className="page-aside" style={expand ? {} : { display: "flex" }}>
      <section className="page-aside-inner">
        <section className="github-tools page-aside-tools">
          <div className="page-aside-top">
            <div className="page-aside-title">
              <span>GitHub Tools</span>
              <sup
                className="text-gradient-1"
                style={{ marginLeft: 3, fontSize: 12 }}
              >
                (BETA)
              </sup>
            </div>
            <section>
              <div className="page-aside-group" onClick={getGitHubIssues}>
                <span className="page-aside-group-left">
                  {importing ? (
                    "Importing..."
                  ) : (
                    <span>
                      <b>Import</b> Assigned Issues
                    </span>
                  )}
                </span>
                <div className="page-aside-group-right">
                  <DownloadIcon size={20} />
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="page-aside-more">
          <div className="page-aside-top">
            <div className="page-aside-title">
              {/* <InsightsIcon size={20} /> */}
              <span>Summary</span>
            </div>
            <section>
              <div className="page-aside-group">
                <span className="page-aside-group-left">
                  Pending Tasks ({counts.pending})
                </span>
                <div className="page-aside-group-right" style={{color : "#9a6700"}}>
                  <IssueOpenedIcon />
                </div>
              </div>
              <div className="page-aside-group">
                <span className="page-aside-group-left">
                  Completed Tasks ({counts.completed})
                </span>
                <div
                  style={{ color: "#2ea44f" }}
                  className="page-aside-group-right"
                >
                  <IssueClosedIcon />
                </div>
              </div>
              <div className="page-aside-group">
                <Link to="docs/get-started" className="page-aside-group-left">
                  Get Started
                </Link>
                <Link to="docs/get-started" className="page-aside-group-right">
                  <ChevronRightIcon />
                </Link>
              </div>
              <div className="page-aside-group" style={{ border: 0 }}>
                <Link to="legal/terms" className="page-aside-group-left">
                  Terms & Privacy
                </Link>
                <Link to="legal/terms" className="page-aside-group-right">
                  <ChevronRightIcon />
                </Link>
              </div>
            </section>
          </div>
        </section>
      </section>
    </aside>
  );
};

export default Aside;
