import React, { useContext, useState } from "react";
import {
  ArchiveIcon,
  CheckIcon,
  MarkGithubIcon,
  ReplyIcon,
  StarIcon,
} from "@primer/octicons-react";
import { dateFormatter, db, firebase } from "../modules";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { AuthContext } from "../contexts";

const TaskItem = ({ doc }) => {
  const location = useLocation();
  const { octokit } = useContext(AuthContext);
  const { title, repo, owner, number, body, type, html_url, date_created } =
    doc.data();

  const [complete, setComplete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState(title);

  // Update issue...
  const updateIssue = async (state) => {
    try {
      const result = await octokit.issues.update({
        owner,
        repo,
        state,
        issue_number: number,
      });
      if (result) {
        const message =
          state === "closed" ? "GitHub Issue Closed" : "GitHub Issue Reopened";
        swal(message, {
          buttons: false,
          timer: 2000,
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error);
      swal("GitHub Issue Close Failed", {
        buttons: false,
        timer: 2000,
        icon: "error",
      });
    }
  };

  // Handle task complete...
  const handleComplete = (event) => {
    event.preventDefault();
    setComplete(true);
    try {
      const taskRef = db.collection("tasks").doc(doc.id);
      taskRef
        .update({
          completed: location.pathname === "/completed" ? false : true,
          date_updated: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          if (type === "github") {
            location.pathname === "/completed"
              ? updateIssue("open")
              : updateIssue("closed");
          }
        });
    } catch (error) {
      swal("GitHub Issue Close Failed", {
        buttons: false,
        timer: 2000,
        icon: "error",
      });
    }
  };

  // Handle task delete...
  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const taskRef = db.collection("tasks").doc(doc.id);
      await taskRef.delete();
      if (type === "github") updateIssue("closed");
    } catch (error) {
      swal("GitHub Issue Delete Failed", {
        buttons: false,
        timer: 2000,
        icon: "error",
      });
    }
  };

  // Handle task edit...
  const handleEdit = async (event) => {
    event.preventDefault();
    try {
      setEdit(false);
      const taskRef = db.collection("tasks").doc(doc.id);
      await taskRef
        .update({
          title: editValue,
          date_updated: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(async () => {
          if (type === "github") {
            const res = await octokit.issues.update({
              owner,
              repo,
              issue_number: number,
              title: editValue,
            });
            if (res) {
              swal("GitHub Issue Updated", {
                buttons: false,
                timer: 2000,
                icon: "success",
              });
            } else {
              swal("GitHub Issue Update Failed", {
                buttons: false,
                timer: 2000,
                icon: "error",
              });
            }
          } else {
            swal("Task Updated", {
              buttons: false,
              timer: 2000,
              icon: "success",
            });
          }
        });
    } catch (error) {
      swal("Task Update Failed", {
        buttons: false,
        timer: 2000,
        icon: "error",
      });
    }
  };

  return (
    <section className="task-item">
      {isEdit ? (
        <form onSubmit={handleEdit}>
          <input
            className="task-item-edit-input"
            type="text"
            value={editValue}
            onChange={(event) => {
              event.preventDefault();
              setEditValue(event.target.value);
            }}
          />
          <div className="task-item-edit-buttons">
            <button
              className="task-item-edit-button"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                setEdit(false);
              }}
            >
              <ReplyIcon />{" "}
              <span className="task-item-edit-button-text">Cancel</span>
            </button>
            <button className="task-item-edit-button" type="submit">
              <CheckIcon />{" "}
              <span className="task-item-edit-button-text">Save</span>
            </button>
          </div>
        </form>
      ) : (
        <div className="task-item-content">
          <div className="task-item-icon">
            <div
              onMouseEnter={() => setComplete(true)}
              onMouseLeave={() => setComplete(false)}
              className="check-icon"
              style={
                complete && location.pathname !== "/completed"
                  ? {
                      color: "#fff",
                      border: "1.5px solid rgb(46, 164, 79)",
                      background: "rgb(46, 164, 79)",
                    }
                  : location.pathname === "/completed"
                  ? {
                      color: "#fff",
                      border: "1.5px solid #fdaeb7",
                      background: "#fdaeb7",
                    }
                  : {}
              }
              onClick={handleComplete}
            >
              {complete && location.pathname !== "/completed" ? (
                <CheckIcon />
              ) : (
                ""
              )}
              {location.pathname === "/completed" ? <ReplyIcon /> : ""}
            </div>
          </div>
          <div className="task-item-content-text">
            <div title={title ? title : ""} className="task-item-title">
              {title ? title : body}
            </div>
            <div className="task-controls">
              <div className="task-item-date">
                <span style={{ marginRight: 3 }}>
                  {`${dateFormatter(date_created).time_since} - ${
                    dateFormatter(date_created).created_on
                  }`}
                </span>{" "}
              </div>
              <div className="task-item-controls">
                {type === "github" ? (
                  <a
                    href={html_url ? html_url : ""}
                    className="task-repo-label task-repo-label-link"
                    target="_blank"
                    rel="noreferrer"
                    style={
                      location.pathname === "/completed"
                        ? { display: "none" }
                        : {}
                    }
                  >
                    <MarkGithubIcon size={12} />{" "}
                    <span
                      style={{
                        marginLeft: 3,
                      }}
                    >
                      VIEW
                    </span>
                  </a>
                ) : (
                  <span
                    style={
                      location.pathname === "/completed"
                        ? { display: "none" }
                        : {}
                    }
                    className="task-repo-label task-repo-label-link"
                  >
                    <StarIcon size={12} />{" "}
                    <span
                      style={{
                        marginLeft: 3,
                      }}
                    >
                      GENERAL
                    </span>
                  </span>
                )}
                <span
                  style={
                    location.pathname === "/" || location.pathname === "/search"
                      ? {}
                      : { display: "none" }
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    // Do something...
                    setEdit(true);
                  }}
                  className="task-repo-label board-label task-edit-btn"
                >
                  Edit
                </span>
              </div>
              <div
                style={
                  location.pathname === "/completed" ? {} : { display: "none" }
                }
                className="task-item-archive"
                onClick={handleDelete}
              >
                <ArchiveIcon />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default TaskItem;
