import { useContext, useEffect, useRef } from "react";
import Chart from "chart.js";
import { Aside, Sidebar } from "../components";
import { DataContext } from "../contexts";

// Chart...
const Productivity = () => {
  const chartRef = useRef(null);
  const { counts } = useContext(DataContext);
  useEffect(() => {
    if (chartRef) {
      // Render the chat...
      new Chart(chartRef.current, {
        type: "doughnut",
        data: {
          labels: ["Completed", "Pending"],
          datasets: [
            {
              label: "Tasks",
              data: [
                counts ? counts.completed : 0,
                counts ? counts.pending : 0,
              ],
              backgroundColor: ["rgb(57, 199, 157)", "rgb(255, 176, 176)"],
              borderColor: ["rgb(57, 199, 157)", "rgb(255, 176, 176)"],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Your Productivity",
            fontSize: 16,
          },
        },
      });
    }
  }, [chartRef, counts]);
  return (
    <main>
      <section className="home-page">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <h3>Productivity</h3>
            </div>
            <section className="chart">
              <canvas
                className="chart-canvas"
                ref={chartRef}
                width="300"
                height="300"
              ></canvas>
            </section>
          </section>
        </section>
        <Aside />
      </section>
    </main>
  );
};

export default Productivity;
