import React from "react";
import { SearchIcon } from "@primer/octicons-react";
import "../assets/styles/search.css";

const SearchInput = ({ placeHolder, onChange, value, className = "" }) => {
  return (
    <div className={`search-component ${className}`}>
      <SearchIcon className="search-icon" />
      <input
        className="search-input"
        type="text"
        placeholder={placeHolder}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default SearchInput;
