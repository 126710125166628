import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AuthProvider, DataProvider } from "../contexts";
import {
  HomePage,
  GithubTodos,
  GeneralTodos,
  CompletedTodos,
  Productivity,
  Archive,
  Docs,
  Terms,
  Privacy,
  All,
  Settings,
  NamePage,
  EmailPage,
  SearchBar,
} from "../pages";
import AuthPage from "../pages/AuthPage";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  return (
    <AuthProvider>
      <DataProvider>
        <Router>
          <Switch>
            <PrivateRoute exact path="/" component={HomePage} />
            <PrivateRoute exact path="/all" component={All} />
            <PrivateRoute exact path="/search" component={SearchBar} />
            <PrivateRoute exact path="/github" component={GithubTodos} />
            <PrivateRoute exact path="/general" component={GeneralTodos} />
            <PrivateRoute exact path="/completed" component={CompletedTodos} />
            <PrivateRoute exact path="/productivity" component={Productivity} />
            <PrivateRoute exact path="/settings" component={Settings} />
            <PrivateRoute exact path="/settings/name" component={NamePage} />
            <PrivateRoute exact path="/settings/email" component={EmailPage} />
            <PrivateRoute exact path="/archive" component={Archive} />
            <PrivateRoute exact path="/docs/get-started" component={Docs} />
            <Route exact path="/auth" component={AuthPage} />
            <Route exact path="/legal/terms" component={Terms} />
            <Route exact path="/legal/privacy" component={Privacy} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </DataProvider>
    </AuthProvider>
  );
};

export default Routes;
