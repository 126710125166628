import React, { useContext, useEffect, useState } from "react";
import { VerifiedIcon } from "@primer/octicons-react";
import { AuthContext } from "../contexts";
import userAvatar from "../assets/images/user-avatar.png";
import "../assets/kazi/sidebar-profile.css";

const SidebarProfile = () => {
  const { currentUser, gitUser } = useContext(AuthContext);
  const { displayName, email, photoURL } = currentUser;
  const [, setGitProfile] = useState(null);

  // Get github profile...
  useEffect(() => {
    if (gitUser)
      setGitProfile({
        login: gitUser.login,
        followers: gitUser.followers,
        location: gitUser.location,
      });
  }, [gitUser]);

  return (
    <aside>
       <section className="side-top">
      <img
        className="side-avatar"
        src={photoURL ? photoURL : userAvatar}
        alt={displayName ? displayName[0] : "KA"}
        title={displayName ? displayName : "Kazi Alien"}
        loading="lazy"
      />
      <div>
        <div className="side-name">
          {displayName ? displayName : "Kazi Alien"}
        </div>
        <div className="side-email">
          <div className="side-email-icon">
            <VerifiedIcon size={16} />
          </div>
          <div className="side-email-text">
            {email ? email : "alien@kazi.co"}
          </div>
        </div>
      </div>
    </section>
    <div>
  </div>
    </aside>
  );
};

export default SidebarProfile;
