import { useState } from "react";
import { IssueOpenedIcon, XIcon } from "@primer/octicons-react";
import { filtered } from "filtered";
import SearchInput from "./SearchInput";

const RepoItem = ({ repo, setSelectedRepo, setClose }) => {
  const { full_name, open_issues_count } = repo;

  // Handle select...
  const handleSelect = (event) => {
    event.preventDefault();
    setSelectedRepo(full_name.toLowerCase());
    setClose(true);
  };

  return (
    <div className="repo-list-item fade-in" onClick={handleSelect}>
      <div className="repo-list-item-left">{full_name.toLowerCase()}</div>
      <div className="repo-list-item-right">
        <div className="repo-list-item-icon">
          <IssueOpenedIcon />
          <span style={{ marginLeft: 5 }}>{open_issues_count}</span>
        </div>
      </div>
    </div>
  );
};

const RepoList = ({
  close,
  fetching,
  repos,
  setClose,
  setSelectedRepo,
  setVisibility,
}) => {
  const [found, setFound] = useState();
  const [selected, setSelected] = useState("all");
  // Close modal...
  const closeModal = (event) => {
    event.preventDefault();
    setClose(true);
  };

  // Filter tasks, algo...
  const doFilter = (event) => {
    event.preventDefault();
    // Query repos...
    const result = filtered({
      data: repos,
      filter: "full_name",
      query: event.target.value,
    });
    setFound(result);
  };

  // Repo component list...
  const repoList = repos ? repos : [];
  const listItems = found ? found : repoList;
  const repoItems = listItems.map((repo) => (
    <RepoItem
      key={repo.id}
      repo={repo}
      setSelectedRepo={setSelectedRepo}
      setClose={setClose}
    />
  ));

  return (
    <section
      style={close ? { display: "none" } : {}}
      className="modal-alert-overlay"
    >
      <section className="modal-alert">
        <div className="modal-title">
          <div className="modal-title-content">Select Project</div>
          <span className="modal-icon" onClick={closeModal}>
            <XIcon size={24} />
          </span>
        </div>
        <section className="modal-content">
          <section className="fade-in">
            <SearchInput
              onChange={doFilter}
              placeHolder={"Search or select..."}
            />
          </section>
        </section>
        <section className="modal-filters">
          <div
            className={
              selected === "all"
                ? "modal-filter modal-filter-public modal-filter-active"
                : "modal-filter modal-filter-public"
            }
            onClick={(event) => {
              event.preventDefault();
              setSelected("all");
              setVisibility("all");
            }}
          >
            All
          </div>{" "}
          <div
            className={
              selected === "public"
                ? "modal-filter modal-filter-public modal-filter-active"
                : "modal-filter modal-filter-public"
            }
            onClick={(event) => {
              event.preventDefault();
              setSelected("public");
              setVisibility("public");
            }}
          >
            Public
          </div>{" "}
          <div
            className={
              selected === "private"
                ? "modal-filter modal-filter-public modal-filter-active"
                : "modal-filter modal-filter-public"
            }
            onClick={(event) => {
              event.preventDefault();
              setSelected("private");
              setVisibility("private");
            }}
          >
            Private
          </div>
        </section>
        <section className="repo-list-container">
          {" "}
          {(found && found.length === 0) || repoList.length === 0 ? (
            <div className="modal-no-result fade-in">
              <span style={{ width: "100%" }}>
                {fetching ? "Loading..." : "No result found..."}
              </span>
            </div>
          ) : (
            repoItems
          )}
        </section>
      </section>
    </section>
  );
};

export default RepoList;
