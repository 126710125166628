import React, { useState } from "react";
import { Aside, Sidebar } from "../components";
import { Link } from "react-router-dom";
import {
  DotIcon,
  ScreenFullIcon,
  ScreenNormalIcon,
} from "@primer/octicons-react";

const Docs = () => {
  document.title = `Docs - Using Kazi`;
  const [expand, setExpand] = useState(true);
  // Handle expand...
  const handleExpand = (event) => {
    event.preventDefault();
    setExpand((expand) => !expand);
  };

  return (
    <main>
      <section className="home-page">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <h3>Docs</h3>
              <span
                className="top-header-inbox set-expand"
                onClick={handleExpand}
                title="Fullscreen"
              >
                {expand ? <ScreenNormalIcon /> : <ScreenFullIcon />}
              </span>
            </div>
            <article className="doc-page">
              <div className="docs-title">About Kazi</div>
              <section>
                <p>
                  Kazi is a task manager for developers who want to plan their
                  day with personal task items or work items. All in a simple
                  and intuitive way.
                </p>
              </section>

              <div className="docs-title">How does this App work?</div>
              <section>
                <p>
                  <DotIcon /> Create general tasks like <b>"Do laundry"</b>. You
                  can also create tasks that are <b>GitHub Issues</b>.
                </p>
                <p>
                  <DotIcon /> GitHub <b>Import Tool</b> allows you to import
                  Issues and Pull Requests assigned to you as Tasks in Kazi.
                </p>
                <p>
                  <DotIcon /> When you close or reopen a task on Kazi, the same
                  is updated on your GitHub Projects for GitHub Tasks.
                </p>

                <p>
                  <DotIcon /> With our{" "}
                  <Link className="link" to="/productivity">
                    Productivity feature
                  </Link>
                  , have a quick and simple snapshot of how your day is going.
                </p>
                <div className="docs-title">Notifications?</div>
                <p>
                  Install from Chrome Tab as a <b>Desktop PWA</b>. Once
                  installed, you can always see the <b>Icon Badge</b> show you
                  the number of <b>Pending Tasks</b>. No noise. Just visual
                  alerts.
                </p>
              </section>
              <div className="text-desc">
                By using our <b>Services</b>, you are agreeing to these
                <Link className="link" to="../legal/terms/">
                  &nbsp;Terms&nbsp;
                </Link>{" "}
                and our
                <Link className="link" to="../legal/privacy/">
                  &nbsp;Privacy Policy
                </Link>
                .
              </div>
            </article>
          </section>
        </section>
        <Aside expand={expand} />
      </section>
    </main>
  );
};
export default Docs;
