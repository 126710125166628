import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <main>
      <section className="sample">
        <section className="header">
          <h1>Kazi Privacy Policy</h1>
          <small>
            <b>We/Us/Kazi</b> refers to <b>kazi.dev</b> and its subdomains
          </small>
          <p>
            When you use our services, you’re trusting us with your information.
            We work hard to protect your information and put you in control.
          </p>
          <h3>Why this Policy?</h3>
          <p>
            <b>This Privacy Policy</b> is meant to help you understand what
            information we collect, why we collect it, and how you can{" "}
            <b>update, manage</b> and <b>delete</b> your information.
          </p>
        </section>
        <article>
          <small>Effective May 01, 2020</small>

          <h3>1. Information Kazi Collects</h3>
          <p>
            The information we collect, and how that information is used,
            depends on how you use our services and how you manage your privacy
            controls if any controls are available.
          </p>

          <h3>2. Information you provide to us</h3>
          <p>
            When you create an account with us via <b>sign-in with GitHub</b>,
            we collect your user profile data such as;
          </p>
          <ul>
            <li>Bio</li>
            <li>Email address</li>
            <li>Name</li>
            <li>Your photo</li>
          </ul>
          <p>
            We also collect a <b>GitHub Access Token</b> and the content you
            create, upload, or receive from others when using our services.
          </p>

          <h3>3. Why we collect this informatiom</h3>
          <p>
            We use your information to create new, deliver, mantain or improve
            our services.
          </p>

          <h3>4. Sharing your information</h3>
          <p>
            We <b>do not share your personal information</b> with companies,
            organizations, or individuals outside of Kazi except in the
            following cases:
          </p>
          <ul>
            <li>With your consent</li>
            <li>For legal reasons</li>
          </ul>

          <h3>5. Keeping your informatiom secure</h3>
          <p>
            We work hard <b>to protect you and Kazi</b> from unauthorized
            access, alteration, disclosure, or destruction of information we
            hold.
          </p>

          <h3>6. Deleting your informatiom</h3>
          <p>
            You can <b>delete</b> it from your Kazi Account at any time (once
            out of beta testing)
          </p>

          <h3>7. Retaining your information</h3>
          <p>
            We <b>retain the data</b> we collect for different periods of time
            depending on what it is, how we use it, and how you configure your
            settings.
          </p>

          <h3>8. Compliance</h3>
          <p>
            We regularly review this <b>Privacy Policy</b> and make sure that we
            process your information in ways that <b>comply</b> with it.
          </p>
        </article>

        <section className="assert">
          <p>Legal - 2021</p>
        </section>
      </section>
      <section className="terms-footer">
        <Link className="back" to="/">
          Home
        </Link>
        <Link className="front text-gradient" to="/legal/terms">
          Terms
        </Link>
      </section>
    </main>
  );
};
export default Privacy;
