import React, { useContext, useState } from "react";
import { firebase } from "../../modules";
import { Aside, Sidebar } from "../../components";
import "../../assets/styles/settings.css";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@primer/octicons-react";
import swal from "sweetalert";
import { AuthContext } from "../../contexts";

const EmailPage = () => {
  const { currentUser } = useContext(AuthContext);
  const { email } = currentUser;
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [pending, setPending] = useState(false);
  const updateProfile = async (event) => {
    event.preventDefault();
    setPending(true);
    const { email } = event.target.elements;
    const user = firebase.auth().currentUser;
    user
      .updateEmail(email.value)
      .then(() => {
        // Update successful...
        setPending(false);
        swal("Profile Updated", {
          buttons: false,
          timer: 1500,
          icon: "success",
        });
      })
      .catch((error) => {
        // An error happened....
        setPending(false);
        setErrorMessage(error.message);
        setTimeout(() => {
          setErrorMessage(null);
        }, 5000);
      });
  };
  return (
    <main>
      <section className="home-page">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <Link className="back-arrow" to="/settings">
                <ArrowLeftIcon size={24} />
              </Link>
              <h3>Settings</h3>
            </div>
            <div className="top-header">Change account email</div>
            <div
              className="app-alert"
              style={errorMessage ? {} : { display: "none" }}
            >
              {errorMessage}
            </div>
            <section className="account">
              <form className="account-page-form" onSubmit={updateProfile}>
                <div className="alert-message"></div>
                <div className="account-page-container">
                  <input
                    className="account-input"
                    type="email"
                    placeholder={email ? email : "No email"}
                    name="email"
                    autoComplete="off"
                    required={true}
                  />
                  <button
                    disabled={pending}
                    className="account-btn"
                    type="submit"
                  >
                    {pending ? "Saving" : "Save"}
                  </button>
                </div>
              </form>
            </section>
          </section>
        </section>
        <Aside />
      </section>
    </main>
  );
};

export default EmailPage;
