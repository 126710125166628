import { createContext, useContext, useEffect, useState } from "react";
import { pwa } from "pwafire";
import { db } from "../modules";
import { AuthContext } from ".";
// Context...
const DataContext = createContext({
  tasks: [],
  counts: {
    completed: 0,
    pending: 0,
  },
});

// Data Provider...
const DataProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [counts, setCounts] = useState({
    completed: 0,
    pending: 0,
  });
  // Effect...
  useEffect(() => {
    const getTasks = (currentUser) => {
      db.collection("tasks")
        .orderBy("timestamp", "desc")
        .where("uid", "==", currentUser.uid)
        .onSnapshot((querySnapshot) => {
          const completed = [];
          const pending = [];
          const getResult = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().completed) {
              completed.push(doc.id);
            } else {
              pending.push(doc.id);
              getResult.push(doc);
            }
          });
          // Set...
          setTasks(getResult);
          setCounts({
            completed: completed.length,
            pending: pending.length,
          });
          // Handle badge...
          if (pending.length > 0) {
            // Set the badge
            pwa.setBadge(pending.length);
          } else {
            pwa.clearBadge();
          }
        });
    };
    if (currentUser && currentUser.uid) {
      getTasks(currentUser);
    } else {
      pwa.clearBadge();
    }

    return () => {
      pwa.clearBadge();
      setTasks([]);
      setCounts({
        completed: 0,
        pending: 0,
      });
    };
  }, [currentUser]);

  return (
    <DataContext.Provider value={{ tasks, counts }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
