import { ScreenFullIcon, ScreenNormalIcon } from "@primer/octicons-react";
import React, { useContext, useEffect, useState } from "react";
import { Sidebar, Aside, TaskItem, NoTasks } from "../components";
import { AuthContext } from "../contexts";
import { db } from "../modules";

const GitHubTodos = () => {
  const { currentUser } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [expand, setExpand] = useState(true);
  // Handle expand...
  const handleExpand = (event) => {
    event.preventDefault();
    setExpand((expand) => !expand);
  };

  useEffect(() => {
    const getTasks = () => {
      db.collection("tasks")
        .orderBy("timestamp", "desc")
        .where("uid", "==", currentUser.uid)
        .onSnapshot((querySnapshot) => {
          const getResult = [];
          querySnapshot.forEach((doc) => {
            const { completed, type } = doc.data();
            if (!completed && type === "github") {
              getResult.push(doc);
            }
          });
          setTasks(getResult);
        });
    };
    getTasks();
  }, [currentUser.uid]);

  // Task component list...
  const taskItems = tasks.map((task) => <TaskItem key={task.id} doc={task} />);
  return (
    <main>
      <section className="home-page">
        <Sidebar />
        <section className="parent">
          <section className="parent-inner">
            <div className="top-header">
              <h3>GitHub</h3>
              <span
                className="top-header-inbox set-expand"
                onClick={handleExpand}
                title="Fullscreen"
              >
                {expand ? <ScreenNormalIcon /> : <ScreenFullIcon />}
              </span>
            </div>
            {taskItems.length > 0 ? (
              <section className="tasks-container">{taskItems}</section>
            ) : (
              <NoTasks />
            )}
          </section>
        </section>
        <Aside expand={expand} />
      </section>
    </main>
  );
};
export default GitHubTodos;
