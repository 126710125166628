import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  document.title = "Kazi - Terms & Privacy";
  return (
    <main>
      <section className="sample">
        <section className="header">
          <h1>Kazi Terms of Service</h1>
          <small>
            <b>We/Us/Kazi</b> refers to <b>kazi.dev</b> and its subdomains
          </small>
          <p>
            By using our Services, you are agreeing to these terms.{" "}
            <b>Please read them</b> carefully.
          </p>
        </section>
        <article>
          <small>Effective May 01, 2020</small>

          <h3>1. Using our services</h3>
          <p>
            You <b>must follow any policies</b> made available to you within the
            Services. Don’t misuse our Services.
          </p>

          <h3>2. Your Kazi account </h3>
          <p>
            You need a <b>Kazi Account</b> in order to use our Services. You
            create your own <b>Kazi Account</b> by signing in with your{" "}
            <b>GitHub Account</b>.
          </p>
          <p>
            You are responsible for the activity that happens on or through your{" "}
            <b>Kazi Account</b>.
          </p>

          <h3>3. Privacy and Copyright protection</h3>
          <p>
            <b>Kazi's</b> <a href="../privacy/">privacy policies</a> explain how
            we treat your personal data and protect your privacy when you use
            our Services. By using our Services, you agree that <b>Kazi</b> can
            use such data in accordance with our privacy policies
          </p>

          <h3>4. Your Content in our Services</h3>
          <p>What belongs to you stays yours.</p>

          <p>
            We display your <b>Profile name, Profile photo, and actions</b> you
            take on <b>Kazi</b> or on third-party applications connected to your{" "}
            <b>Kazi</b> Account.
          </p>

          <h3>5. Modifying and Terminating our Services</h3>
          <p>
            We are constantly changing and improving our Services. We may add or
            remove functionalities or features, and we may suspend or stop a
            Service altogether.
          </p>

          <p>
            You can stop using our Services at any time once we are out of beta;
            although we’ll be sorry to see you go. <b>Kazi</b> may also stop
            providing Services to you, or add or create new limits to our
            Services at any time.
          </p>

          <p>
            If we discontinue a Service, where reasonably possible, we will give
            you reasonable advance notice and a chance to get information out of
            that Service.
          </p>

          <h3>6. Liability for our Services</h3>
          <p>
            When permitted by law, <b>Kazi</b> will not be responsible for lost
            profits, revenues, or data, financial losses or indirect, special,
            consequential, exemplary, or punitive damages.
          </p>

          <h3>7. Retaining your information</h3>
          <p>
            We retain the data we collect for different periods of time
            depending on what it is, how we use it, and how you configure your
            settings.
          </p>

          <h3>8. Business uses of our Services</h3>
          <p>
            If you are using our Services on behalf of a business, that business
            accepts these terms.
          </p>
          <h3>About these Terms</h3>
          <p>
            We may modify these terms or any additional terms that apply to a
            Service to, for example, reflect changes to the law or changes to
            our Services.
          </p>
        </article>

        <section className="assert">
          <p>Legal - 2021</p>
        </section>
      </section>
      <section className="terms-footer">
        <Link className="back" to="/">
          Home
        </Link>
        <Link className="front text-gradient" to="/legal/privacy">
          Privacy
        </Link>
      </section>
    </main>
  );
};
export default Terms;
