import { db } from "./Firebase";
const getTasks = (uid, filter = "general") => {
  const getResult = [];
  db.collection("tasks")
    .orderBy("timestamp", "desc")
    .where("uid", "==", uid)
    .onSnapshot((querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().type === filter) {
          data.push(doc);
        }
      });
      data.forEach((doc) => getResult.push(doc));
    });
  return getResult;
};

export { getTasks };
