import React, { useCallback, useContext, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import { db, firebase } from "../modules";
import { MarkGithubIcon } from "@primer/octicons-react";
import { Link } from "react-router-dom";
import Kazi from "../assets/images/kazi.png";
import { AuthContext } from "../contexts";
import "../assets/styles/auth.css";

// Create an instance of the Google provider object...
const provider = new firebase.auth.GithubAuthProvider();

const AuthPage = ({ history }) => {
  // Page title...
  document.title = `Auth - Kazi`;
  const { currentUser } = useContext(AuthContext);
  // Handle login...
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        // Specify additional OAuth 2.0 scope...
        provider.addScope("repo");
        const result = await firebase.auth().signInWithRedirect(provider);
        // Redirect to homepage...
        if (result) {
          history.push("/");
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    [history]
  );

  // Effect....
  useEffect(() => {
    const getUserToken = async () => {
      try {
        const result = await firebase.auth().getRedirectResult();
        if (result.additionalUserInfo) {
          const { username, profile } = result.additionalUserInfo;
          // Save profile...
          db.collection("tokens").doc(currentUser.uid).set({
            token: result.credential.accessToken,
            username: username,
            // email:email,
            html_url: profile.html_url,
            location: profile.location,
            avatar_url: profile.avatar_url,
            bio: profile.bio,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (currentUser !== null) {
      getUserToken();
    }
  }, [currentUser]);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <main className="auth-main">
      <div className="b1">
        <main className="main1 fade-in">
          <section className="flex1">
            <div className="col-md-7">
              <div className="text-logo text-gradient-2">
                <i>
                  <b>k</b>
                </i>
                azi
              </div>
              <h1 id="hello" className="second h1">
                Built for <span>Pro</span>ductivity.
              </h1>
              <p id="blurb" className="third lead">
                <b>Plan</b> your day by creating <b>Tasks</b> that you can also
                publish as <b>GitHub Issues</b>.
              </p>
            </div>
            <div className="auth">
              <img className="text-logo-circle" src={Kazi} alt="Kazi" />
              <h2>Daily tasks<br/> that need your love</h2>
              <button id="auth" className="button1" onClick={handleLogin}>
                <MarkGithubIcon /> Sign in with GitHub
              </button>
              <p>
                By continuing, you are agreeing to accept our{" "}
                <Link className="a" to="/legal/terms/">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link className="a" to="/legal/privacy/">
                  Privacy Policy
                </Link>
              </p>
            </div>
          </section>
        </main>
        <footer>
          <span>
            © 2021{" "}
            <b>
              <i>Todo</i>
            </b>
            code, Inc
          </span>{" "}
          <Link className="a" to="/legal/terms/">
            Terms
          </Link>
          <Link className="a" to="/legal/privacy/">
            Privacy
          </Link>
          <Link className="a" to="/about/">
            About
          </Link>
        </footer>
      </div>
    </main>
  );
};

export default withRouter(AuthPage);
